import React, { useEffect, useState } from "react"
import { MDBDataTable } from "mdbreact"
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Button,
  UncontrolledAlert,
  Progress,
} from "reactstrap"
import { connect } from "react-redux"
import Swal from "sweetalert2"
// Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../../store/actions"

import { Link } from "react-router-dom"
import { format } from "date-fns"

const AllApprovedRecipeReviews = props => {
  document.title = "PFP Admin - All Approved Recipe Reviews"

  const breadcrumbItems = [
    { title: "Approved Recipe Reviews", link: "#" },
    { title: "All Approved Recipe Reviews", link: "#" },
  ]

  const [data1, setData] = useState(null)
  const [records, setRecords] = useState([])
  const [update, setUpdate] = useState(0)
  const [alertVisible, setAlertVisible] = useState(false)
  const [error, setError] = useState("")
  const token = localStorage.getItem("token")
  const [loading, setLoading] = useState(false)
  const [counter, setCounter] = useState(0)

  useEffect(() => {
    props.setBreadcrumbItems("Manage Approved Recipe Reviews", breadcrumbItems)
  }, [props])

  useEffect(() => {

    const fetchAllCategories = async () => {
      try{
        setLoading(true)

        setCounter(0)
        let progress = 0
        const interval = setInterval(() => {
          setCounter(prev => {
            const nextValue = prev + 1
            if (nextValue >= 100) {
              clearInterval(interval)
            }
            return nextValue
          })
        }, 25)
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/backend/recipe/reviews/list?is_approved=approved`,{
            headers: {
              "Authorization": `Bearer ${token}`,
            }
          }
        )
        const json = await response.json()
  
        if (response.ok) {
          setData(json.data)
          setRecords(json.data)
        } else {
          setRecords([])
        }
      }catch(error){
        alert(error)
      }finally{
        setCounter(100)
        setLoading(false)
      }
   
    }

    fetchAllCategories()
  }, [update])

  const handleApprove = async (approved, id) => {
    const data = {
      approved,
    }

    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/backend/recipe/reviews/approval/${id}`,
      {
        method: "PATCH",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "Application/json",
          
            "Authorization": `Bearer ${token}`,
          
        },
      },
    )

    const json = await response.json()

    if (response.ok) {
      setError(json.message)
      setAlertVisible(true)
      setTimeout(() => setAlertVisible(false), 10000)

      setUpdate(update + 1)
    }
  }

  const data = {
    columns: [
      // {
      //   label: "Image",
      //   field: "image",
      //   sort: false,
      //   width: 150,
      // },
      {
        label: "User",
        field: "user_name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Recipe Name",
        field: "recipe_name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Review",
        field: "review",
        sort: "asc",
        width: 150,
      },
      {
        label: "Rating",
        field: "rating",
        sort: "asc",
        width: 270,
      },
      {
        label: "Approval Date",
        field: "ap_date",
        sort: "asc",
        width: 270,
      },
      {
        label: "Date Reviewed",
        field: "date",
        sort: "asc",
        width: 270,
      },
      {
        label: "Action",
        field: "action",
        sort: false,
        width: 100,
      },
    ],
    rows: records.map(row => ({
      user_name: row.user_name,
      recipe_name: row.recipe_name,
      rating: row.rating,
      date: format(row.created_at, "MMM dd, yyyy - hh:mm:ss"),
      ap_date: format(row.updated_at, "MMM dd, yyyy - hh:mm:ss"),
      review: <>{row.review} <br/>
      {
        row.files.length > 0? 
        <>
        {
          row.files.map((file)=>(
            <img height="80px" alt="recipe" className="mx-1" src={file.cdn_image_small}/>
          ))
        }
        </>
        :
        <></>
      }
      </>,

   
      // image: (
      //   <>
      //     {row.files.length > 0 ? (
      //       <img height="50px" alt="recipe" src={row.files[0].image_url}/>
      //     ) : (
      //       <img height="50px" alt="not found" src="/no-image.png"/>
      //     )}
      //   </>
      // ),
      action: (
        // <div className="d-flex justify-content-center gap-2">
        <div className="d-flex justify-content-end gap-2">
          <button
            onClick={() => handleApprove("pending", row.id)}
            className="btn btn-sm"
            style={{ backgroundColor: "#1664f5", color: "white" }}
          >
            Pending
          </button>{" "}
   
          {/* <Link className="btn btn-sm btn-primary">Edit</Link>{" "}
          <button
            onClick={() => handleDelete(row.id)}
            className="btn btn-sm btn-danger"
          >
            Delete
          </button> */}
        </div>
        // </div>
      ),
    })),
  }

  const handleDelete = myId => {
    console.log(myId)
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success btn-sm ms-2",
        cancelButton: "btn btn-sm",
        popup: "my-custom-modal",
        title: "my-custom-title",
        icon: "my-custom-icon",
        content: "my-custom-text",
      },
      buttonsStyling: false,
    })
    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      })
      .then(result => {
        if (result.isConfirmed) {
          const deleteUser = async d_id => {
            const response = await fetch(
              `${process.env.REACT_APP_API_URL}/api/backend/recipes/delete/${d_id}`,
              {
                method: "DELETE",
                headers: {
                  "Authorization": `Bearer ${token}`,
                }
              },
            )
            setUpdate(update + 1)
          }
          deleteUser(myId)
          swalWithBootstrapButtons.fire({
            title: "Deleted!",
            text: "Review has been deleted.",
            icon: "success",
          })
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          swalWithBootstrapButtons.fire({
            title: "Cancelled",
            text: "Review is safe :)",
            icon: "error",
          })
        }
      })
  }

  return (
    <React.Fragment>
      <Row>
        <Col className="col-12">
          {alertVisible && ( // Conditionally render the alert
            <UncontrolledAlert color="success">
              <strong>Success!</strong> {error}
            </UncontrolledAlert>
          )}
          <Card>
            <CardBody>
              <CardTitle className="h4 d-flex justify-content-between">
                All Approved Recipe Review
              </CardTitle>
              {loading ? (
                <Col lg={12}>
                  <Progress
                    striped
                    animated
                    color="bg-primary"
                    value={counter}
                  />
                </Col>
              ) : (
              <MDBDataTable
                responsive
                bordered
                data={data}
                striped
                data-mdb-loading="true"
              />
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(AllApprovedRecipeReviews)
