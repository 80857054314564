import React, { useContext, useEffect, useState, useRef } from "react"
import { MDBDataTable } from "mdbreact"
import "./AllRestaurants.css"
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Button,
  Pagination,
  PaginationItem,
  PaginationLink,
  Input,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Progress,
  UncontrolledAlert,
} from "reactstrap"
import { connect } from "react-redux"
import Swal from "sweetalert2"
// Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions"

import "./datatables.scss"
import { Link, useSearchParams } from "react-router-dom"
import styled from "styled-components"
import { RestaurantPageContext } from "App"

const PendingRestaurants = props => {
  document.title = "PFP Admin - All Restaurants"

  const breadcrumbItems = [
    { title: "Restaurant", link: "#" },
    { title: "All Restaurants", link: "#" },
  ]

  const [data1, setData] = useState(null)
  const [records, setRecords] = useState([])
  const [update, setUpdate] = useState(0)
  const { page, setPage } = useContext(RestaurantPageContext)
  const [limit, setLimit] = useState(10)
  const [total, setTotal] = useState(0)
  const [lastPage, setLastPage] = useState(0)
  const [search, setSearch] = useState("")
  // const [currentPage, setCurrentPage] = useState(1)
  const [drp_close1, setDrp_close1] = useState(false)
  const [loading, setLoading] = useState(false)
  const [counter, setCounter] = useState(0)
  const deliverModalCloseButton = useRef(null)
  const [activeModal, setActiveModal] = useState(null)
  const [alertVisible, setAlertVisible] = useState(false)
  const [error, setError] = useState("")
  const [reasons, setReason] = useState("")
  const [explaination, setExplanation] = useState("")

  const token = localStorage.getItem("token")

  useEffect(() => {
    props.setBreadcrumbItems("Restaurants", breadcrumbItems)
  }, [props])

  useEffect(() => {
    const fetchAllRestaurants = async () => {
      if (search.trim() !== "") {
        await setLimit(10)
        await setPage(1)
      }

      try {
        setLoading(true)
      
         setCounter(0)
         let progress = 0;
         const interval = setInterval(() => {
           setCounter((prev) => {
             const nextValue = prev + 1;
             if (nextValue >= 100) {
               clearInterval(interval); 
             }
             return nextValue;
           });
         }, 25); 
   
          const searchParams = new URLSearchParams({
            offset: page,
            limit: limit,
            search_term: search,
            is_approved: 0
          })
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/backend/restaurants/list?${searchParams}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        )
        const json = await response.json()

      //  await setCounter(75)
        if (response.ok) {
          setData(json.data)
          setRecords(json.data)
          setLastPage(json.pagination.last_page)
          setTotal(json.pagination.total)
          // setPage(json.pagination.current_page)
        } else {
          setRecords([])
        }
      } catch (error) {
        alert(error)
      } finally {
        setCounter(100)
        setLoading(false)
      }
    }

    fetchAllRestaurants()
  }, [update, limit, page, search])

    // Trigger the modal by setting the active row's ID
    const handleModalOpen = id => {
      setActiveModal(id)
    }
  
    // Close the modal
    const handleModalClose = () => {
      setActiveModal(null)
    }
  

  const handleApprove = async (active, id) => {
    const data = active === 2 ? { active, reasons, explaination } : { active }

    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/backend/restaurants/approval/${id}`,
      {
        method: "PATCH",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "Application/json",
          Authorization: `Bearer ${token}`,
        },
      },
    )

    const json = await response.json()

    if (response.ok) {
      setError(json.message)
      setAlertVisible(true)
      setTimeout(() => setAlertVisible(false), 10000)
      setUpdate(update + 1)
      handleModalClose()
    }
  }


  const data = {
    columns: [
      {
        label: "Logo",
        field: "image",
        sort: false,
        width: 150,
      },
      {
        label: "Name",
        field: "restaurant_name",
        // sort: "asc",
        width: 150,
      },
      {
        label: "Category",
        field: "restaurant_categories",
        // sort: "asc",
        width: 150,
 
        
      },
      {
        label: "Status",
        field: "status",
        sort: false,
        width: 100,
      },
      {
        label: "Action",
        field: "action",
        sort: false,
        width: 100,
      },

    ],
    rows: records.map(row => ({
      image: (
        <>
          <img
            height="80"
            className="avatar-sm rounded bg-light"
            src={row.files[0] ? row.files[0].cdn_image_small : "no image"}
          ></img>
        </>
      ),
      restaurant_name: row.restaurant_name,
      restaurant_categories: (
        <>
          {row.restaurant_categories &&
            row.restaurant_categories.map(cat => cat.restaurant_category_name + ", ")}
        </>
      ),
      status: (
        // <div className="d-flex justify-content-center gap-2">
        <div className="d-flex justify-content-end gap-2">
        <button
          onClick={() => handleModalOpen(row.id)}
          className="btn btn-sm btn-warning"
        >
          Reject
        </button>
        <button
          onClick={() => handleApprove(1, row.id)}
          className="btn btn-sm"
          style={{ backgroundColor: "#24A97B", color: "white" }}
        >
          Approve
        </button>{" "}
  
      </div>
        // </div>
      ),
      action: (
        // <div className="d-flex justify-content-center gap-2">
        <div className="d-flex justify-content-end gap-2">
          <Link
            to={`/manage-discounts/${row.id}`}
            className="btn btn-sm"
            style={{ backgroundColor: "#24A97B", color: "white" }}
          >
            Manage Discounts
          </Link>{" "}
          <Link to={`/manage-menu/${row.id}`} className="btn btn-sm btn-info">
            Manage Menu
          </Link>{" "}
          <Link
            to={`/manage-locations/${row.id}`}
            className="btn btn-sm btn-dark"
          >
            Manage Locations
          </Link>{" "}
          <Link
            to={`/edit-restaurant/${row.id}/all`}
            className="btn btn-sm btn-primary d-flex align-items-center"
          >
            Edit
          </Link>{" "}
       
      
    
        </div>
        // </div>
      ),
    })),
  }

  const handleDelete = myId => {
    console.log(myId)
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success btn-sm ms-2",
        cancelButton: "btn btn-sm",
        popup: "my-custom-modal",
        title: "my-custom-title",
        icon: "my-custom-icon",
        content: "my-custom-text",
      },
      buttonsStyling: false,
    })
    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      })
      .then(result => {
        if (result.isConfirmed) {
          const deleteUser = async d_id => {
            const response = await fetch(
              `${process.env.REACT_APP_API_URL}/api/backend/restaurants/delete/${d_id}`,
              {
                method: "DELETE",
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              },
            )
            setUpdate(update + 1)
          }
          deleteUser(myId)
          swalWithBootstrapButtons.fire({
            title: "Deleted!",
            text: "Restaurant has been deleted.",
            icon: "success",
          })
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          swalWithBootstrapButtons.fire({
            title: "Cancelled",
            text: "Restaurant is safe :)",
            icon: "error",
          })
        }
      })
  }

  return (
    <React.Fragment>
      <Row>
        <Col className="col-12">
        {alertVisible && ( // Conditionally render the alert
            <UncontrolledAlert color="success">
              <strong>Success!</strong> {error}
            </UncontrolledAlert>
          )}
          <Card>
            <CardBody>
              <CardTitle className="h4 d-flex justify-content-between">
                Pending Restaurants{" "}
            
              </CardTitle>
              {activeModal && (
                <div
                  className="modal fade show"
                  tabIndex={-1}
                  aria-labelledby="exampleModalLabel2"
                  aria-hidden="true"
                  style={{ display: "block", backdropFilter: "blur(5px)" }}
                >
                  <div className="modal-dialog modal-dialog-centered">
                    <form
                      className="modal-content"
                      onSubmit={e => {
                        e.preventDefault() // Prevent page reload
                        handleApprove(2, activeModal) // Call the function to handle rejection
                      }}
                    >
                      <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel2">
                          Reason of Rejection
                        </h5>
                        <button
                          type="button"
                          className="btn-close"
                          onClick={handleModalClose}
                        ></button>
                      </div>
                      <div className="modal-body">
                        <input
                          type="text"
                          className="form-control mb-2"
                          onChange={e => setReason(e.target.value)}
                          placeholder="Enter Valid Reason"
                        
                          required
                        />
                        <textarea
                          type="text"
                          className="form-control"
                          onChange={e => setExplanation(e.target.value)}
                          placeholder="Explain"
                          rows={3}
                          required
                        ></textarea>
                      </div>
                      <div className="modal-footer">
                        <button type="submit" className="btn btn-danger">
                          Confirm Reject
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              )}
              {/* <div className="container-fluid mb-2"> */}
              <Row className="d-flex justify-content-between">
                <Col className="col-md-4 col-sm-12 d-flex justify-content-start mb-2">
                  <ButtonDropdown
                    className="btn-group"
                    isOpen={drp_close1}
                    toggle={() => setDrp_close1(!drp_close1)}
                  >
                    <DropdownToggle className="btn btn-primary" type="button">
                      Showing Entries {limit}{" "}
                      <i className="mdi mdi-chevron-down"></i>
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem onClick={() => setLimit(10)}>
                        10
                      </DropdownItem>
                      <DropdownItem onClick={() => setLimit(25)}>
                        25
                      </DropdownItem>
                      <DropdownItem onClick={() => setLimit(50)}>
                        50
                      </DropdownItem>
                      <DropdownItem onClick={() => setLimit(100)}>
                        100
                      </DropdownItem>
                    </DropdownMenu>
                  </ButtonDropdown>
                </Col>
                <Col className="col-md-4 col-sm-12 d-flex justify-content-end align-items-center mb-2">
                  <Input
                    type="text"
                    placeholder="Search..."
                    className="form-control"
                    onChange={e => setSearch(e.target.value)}
                  />
                </Col>
              </Row>
              {/* </div> */}

              {loading ? (
                <Col lg={12}>
                  <Progress striped animated color="bg-primary" value={counter} />
                </Col>
              ) : (
                <MDBDataTable
                  id="restaurant-table"
                  responsive
                  bordered
                  striped
                  data={data}
                  paging={false}
                  searching={false}
                />
              )}

              <div className="container-fluid">
                <Row>
                  <Col className="col-md-6 col-sm-12 d-flex justify-content-start align-items-center">
                    Showing {limit} restaurants of {total}.
                  </Col>
                  <Col className="col-md-6 col-sm-12 d-flex justify-content-end align-items-center">
                    <nav>
                      <Pagination
                        aria-label="Page navigation example"
                        size="md"
                      >
                        {page > 1 ? (
                          <PaginationItem>
                            <PaginationLink
                              onClick={
                                page > 1
                                  ? () => setPage(page - 1)
                                  : () => setPage(page)
                              }
                              previous
                            >
                              Previous
                            </PaginationLink>
                          </PaginationItem>
                        ) : (
                          <></>
                        )}

                        <PaginationItem onClick={() => setPage(1)}>
                          <PaginationLink>1</PaginationLink>
                        </PaginationItem>
                        <PaginationItem>
                          <PaginationLink>...</PaginationLink>
                        </PaginationItem>

                        {page > 2 ? (
                          <PaginationItem onClick={() => setPage(page - 2)}>
                            <PaginationLink>{page - 2}</PaginationLink>
                          </PaginationItem>
                        ) : (
                          <></>
                        )}
                        {page > 1 ? (
                          <PaginationItem onClick={() => setPage(page - 1)}>
                            <PaginationLink>{page - 1}</PaginationLink>
                          </PaginationItem>
                        ) : (
                          <></>
                        )}

                        <PaginationItem active>
                          <PaginationLink>{page}</PaginationLink>
                        </PaginationItem>

                        {page < lastPage ? (
                          <PaginationItem onClick={() => setPage(page + 1)}>
                            <PaginationLink>{page + 1}</PaginationLink>
                          </PaginationItem>
                        ) : (
                          <></>
                        )}
                        {page < lastPage ? (
                          <PaginationItem onClick={() => setPage(page + 2)}>
                            <PaginationLink>{page + 2}</PaginationLink>
                          </PaginationItem>
                        ) : (
                          <></>
                        )}

                        <PaginationItem>
                          <PaginationLink>...</PaginationLink>
                        </PaginationItem>
                        <PaginationItem>
                          <PaginationLink onClick={() => setPage(lastPage)}>
                            {lastPage}
                          </PaginationLink>
                        </PaginationItem>
                        {page !== lastPage ? (
                          <PaginationItem>
                            <PaginationLink
                              onClick={() => setPage(page + 1)}
                              next
                            >
                              Next
                            </PaginationLink>
                          </PaginationItem>
                        ) : (
                          <></>
                        )}
                      </Pagination>
                    </nav>
                  </Col>
                </Row>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(PendingRestaurants)
