import React, { useEffect, useState } from "react"
import { MDBDataTable } from "mdbreact"
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Button,
  Progress,
} from "reactstrap"
import { connect } from "react-redux"
import Swal from "sweetalert2"
// Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions"

import "./datatables.scss"
import { Link } from "react-router-dom"

const AllCategories = props => {
  document.title = "PFP Admin - All Categories"

  const breadcrumbItems = [
    { title: "Restaurant", link: "#" },
    { title: "All Categories", link: "#" },
  ]

  const [data1, setData] = useState(null)
  const [records, setRecords] = useState([])
  const [update, setUpdate] = useState(0)
  const token = localStorage.getItem("token")
  const [loading, setLoading] = useState(false)
  const [counter, setCounter] = useState(0)
  useEffect(() => {
    props.setBreadcrumbItems("Restaurant Categories", breadcrumbItems)
  }, [props])

  useEffect(() => {
    const fetchAllCategories = async () => {
      try {
        setLoading(true)

        setCounter(0)
        let progress = 0
        const interval = setInterval(() => {
          setCounter(prev => {
            const nextValue = prev + 1
            if (nextValue >= 100) {
              clearInterval(interval)
            }
            return nextValue
          })
        }, 25)
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/backend/restaurants/category/list`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        )
        const json = await response.json()

        if (response.ok) {
          setData(json.data)
          setRecords(json.data)
        } else {
          setRecords([])
        }
      } catch (error) {
        alert(error)
      } finally {
        setCounter(100)
        setLoading(false)
      }
    }

    fetchAllCategories()
  }, [update])

  const data = {
    columns: [
      {
        label: "Image",
        field: "image",
        sort: false,
        width: 150,
      },
      {
        label: "Name",
        field: "category_name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Description",
        field: "category_description",
        sort: "asc",
        width: 270,
      },
      {
        label: "Restaurants",
        field: "restaurants",
        sort: "asc",
        width: 270,
      },
      {
        label: "Status",
        field: "active",
        sort: "asc",
        width: 270,
      },
      {
        label: "Action",
        field: "action",
        sort: false,
        width: 100,
      },
    ],
    rows: records.map(row => ({
      category_name: row.category_name,
      category_description: row.category_description,
      restaurants: row.restaurant_count,
      active: row.active === 1 ? "Active" : "Inactive",
      image: (
        <>
          <img
            height="80"
            className="avatar-sm rounded bg-light"
            src={row.cdn_image_small}
          ></img>
        </>
      ),
      action: (
        // <div className="d-flex justify-content-center gap-2">
        <div className="d-flex justify-content-end gap-2">
          <Link
            to={`/edit-restaurant-category/${row.id}`}
            className="btn btn-sm btn-primary"
          >
            Edit
          </Link>{" "}
          <button
            onClick={() => handleDelete(row.id)}
            className="btn btn-sm btn-danger"
          >
            Delete
          </button>
        </div>
        // </div>
      ),
    })),
  }

  const handleDelete = myId => {
    console.log(myId)
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success btn-sm ms-2",
        cancelButton: "btn btn-sm",
        popup: "my-custom-modal",
        title: "my-custom-title",
        icon: "my-custom-icon",
        content: "my-custom-text",
      },
      buttonsStyling: false,
    })
    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      })
      .then(result => {
        if (result.isConfirmed) {
          const deleteUser = async d_id => {
            const response = await fetch(
              `${process.env.REACT_APP_API_URL}/api/backend/restaurants/category/delete/${d_id}`,
              {
                method: "DELETE",
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              },
            )
            const json = await response.json()
            if (response.ok) {
              swalWithBootstrapButtons.fire({
                title: "Deleted!",
                text: "Category has been deleted.",
                icon: "success",
              })
            } else {
              console.log(json.message)
              swalWithBootstrapButtons.fire({
                title: "Cancelled",
                text: json.message,
                icon: "error",
              })
            }

            setUpdate(update + 1)
          }
          deleteUser(myId)
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          swalWithBootstrapButtons.fire({
            title: "Cancelled",
            text: "Category is safe :)",
            icon: "error",
          })
        }
      })
  }

  return (
    <React.Fragment>
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <CardTitle className="h4 d-flex justify-content-between">
                All Restaurant Categories{" "}
                <Link to={`/add-restaurant-category`}>
                  <Button
                    type="button"
                    color="primary"
                    className="waves-effect waves-light"
                  >
                    Add New Category
                  </Button>
                </Link>
              </CardTitle>
              {loading ? (
                <Col lg={12}>
                  <Progress
                    striped
                    animated
                    color="bg-primary"
                    value={counter}
                  />
                </Col>
              ) : (
                <MDBDataTable
                  responsive
                  bordered
                  striped
                  data={data}
                  data-mdb-loading="true"
                />
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(AllCategories)
