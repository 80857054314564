import React, { useEffect, useState } from "react"

import { connect } from "react-redux"
import { Row, Col, Card, CardBody, CardTitle, Progress } from "reactstrap"

// Pages Components
import Miniwidget from "./Miniwidget"
import MonthlyEarnings from "./montly-earnings"
import EmailSent from "./email-sent"
import MonthlyEarnings2 from "./montly-earnings2"
import Inbox from "./inbox"
import RecentActivity from "./recent-activity"
import WidgetUser from "./widget-user"
import YearlySales from "./yearly-sales"
import LatestTransactions from "./latest-transactions"
import LatestOrders from "./latest-orders"

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions"

const Dashboard = props => {
  document.title = "Dashboard | PFP - Admin Dashboard"

  const breadcrumbItems = [
    { title: "PFP", link: "#" },
    { title: "Dashboard", link: "#" },
  ]

  useEffect(() => {
    props.setBreadcrumbItems("Dashboard", breadcrumbItems)
  })

  const [iframeUrl, setIframeUrl] = useState("")
  const token = localStorage.getItem("token")
  const [loading, setLoading] = useState(false)
  const [counter, setCounter] = useState(0)
  useEffect(() => {
    const fetchUrl = async () => {
      console.log("hi")
      try {
        setLoading(true)

        setCounter(0)
        let progress = 0
        const interval = setInterval(() => {
          setCounter(prev => {
            const nextValue = prev + 1
            if (nextValue >= 100) {
              clearInterval(interval)
            }
            return nextValue
          })
        }, 25)
        const response = await fetch(
          "https://api.pakistanfoodportal.com/api/backend/auth/metabase_auth",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        )
        const url = await response.json()

        if (response) {
          setIframeUrl(url.data)
          console.log(url.data)
        }
      } catch (error) {alert(error)}
      finally{
        setCounter(100)
        setLoading(false)
      }
    }

    fetchUrl()
    const interval = setInterval(fetchUrl, 15 * 60 * 1000); // 15 minutes in milliseconds

    // Cleanup the interval on component unmount
    return () => clearInterval(interval);
  }, [])

  const reports = [
    {
      title: "Orders",
      iconClass: "cube-outline",
      total: "1,587",
      average: "+11%",
      badgecolor: "info",
    },
    {
      title: "Revenue",
      iconClass: "buffer",
      total: "$46,782",
      average: "-29%",
      badgecolor: "danger",
    },
    {
      title: "Average Price",
      iconClass: "tag-text-outline",
      total: "$15.9",
      average: "0%",
      badgecolor: "warning",
    },
    {
      title: "Product Sold",
      iconClass: "briefcase-check",
      total: "1890",
      average: "+89%",
      badgecolor: "info",
    },
  ]

  return (
    <React.Fragment>
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <CardTitle className="h4 d-flex justify-content-between">
                Statistics
              </CardTitle>
              {loading ? (
                <Col lg={12}>
                  <Progress
                    striped
                    animated
                    color="bg-primary"
                    value={counter}
                  />
                </Col>
              ) : (
              <iframe 

                src={iframeUrl}
                frameBorder={0}
                style={{ width: "100%", height: "80vh" }} // Responsive iframe
                allowTransparency
              />
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(Dashboard)
