import React from "react"
import { Navigate } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Pages Calendar
import Calendar from "../pages/Calendar/index"

//Email
import EmailInbox from "../pages/Email/email-inbox"
import EmailRead from "../pages/Email/email-read"
import EmailCompose from "../pages/Email/email-compose"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

// Inner Authentication
import Login1 from "../pages/AuthenticationInner/Login"
import Register1 from "../pages/AuthenticationInner/Register"
import Recoverpw from "../pages/AuthenticationInner/Recoverpw"
import LockScreen from "../pages/AuthenticationInner/auth-lock-screen"

// Dashboard
import Dashboard from "../pages/Dashboard/index"

//Charts
import ChartsAppex from "../pages/Charts/charts-appex";
import ChartsJs from "../pages/Charts/charts-chartjs";
import ChartsKnob from "../pages/Charts/charts-knob";
import ChartsC3 from "../pages/Charts/charts-c3";
import ChartsSparkLine from "../pages/Charts/charts-sparkline";

// Maps
import MapsGoogle from "../pages/Maps/MapsGoogle"
import MapsVector from "../pages/Maps/MapsVector"

//Icons
import IconMaterialdesign from "../pages/Icons/IconMaterialdesign"
import Iconion from "../pages/Icons/Iconion"
import IconFontawesome from "../pages/Icons/IconFontawesome"
import IconThemify from "../pages/Icons/IconThemify"
import IconDripicons from "../pages/Icons/IconDripicons"
import IconTypicons from "../pages/Icons/IconTypicons"

//Tables
import BasicTables from "../pages/Tables/BasicTables"
import DatatableTables from "../pages/Tables/DatatableTables"
import ResponsiveTables from "../pages/Tables/ResponsiveTables"
import EditableTables from "../pages/Tables/EditableTables"

// Forms
import FormElements from "../pages/Forms/FormElements"
import FormAdvanced from "../pages/Forms/FormAdvanced"
import FormEditors from "../pages/Forms/FormEditors"
import FormValidations from "../pages/Forms/FormValidations"
import FormUpload from "../pages/Forms/FormUpload"
import FormXeditable from "../pages/Forms/FormXeditable"

//Ui
import UiAlert from "../pages/Ui/UiAlert"
import UiButtons from "../pages/Ui/UiButtons"
import UiBadge from "../pages/Ui/UiBadge"
import UiCards from "../pages/Ui/UiCards"
import UiCarousel from "../pages/Ui/UiCarousel"
import UiDropdown from "../pages/Ui/UiDropdown"
import UiGrid from "../pages/Ui/UiGrid"
import UiImages from "../pages/Ui/UiImages"
import UiLightbox from "../pages/Ui/UiLightbox"
import UiModal from "../pages/Ui/UiModal"
import UiPagination from "../pages/Ui/UiPagination"
import UiPopoverTooltips from "../pages/Ui/UiPopoverTooltips"
import UiProgressbar from "../pages/Ui/UiProgressbar"
import UiTabsAccordions from "../pages/Ui/UiTabsAccordions"
import UiTypography from "../pages/Ui/UiTypography"
import UiVideo from "../pages/Ui/UiVideo"
import UiSessionTimeout from "../pages/Ui/UiSessionTimeout"
import UiRangeSlider from "../pages/Ui/UiRangeSlider"

//Extra Pages
import PagesTimeline from "../pages/Extra Pages/pages-timeline";
import PagesInvoice from "../pages/Extra Pages/pages-invoice";
import PagesDirectory from "../pages/Extra Pages/pages-directory";
import PagesBlank from "../pages/Extra Pages/pages-blank";
import Pages404 from "../pages/Extra Pages/pages-404";
import Pages500 from "../pages/Extra Pages/pages-500";
import UiUtilities from "pages/Ui/UiUtilities"
import UiColors from "pages/Ui/UiColors"
import UiOffcanvas from "pages/Ui/UiOffcanvas"
import Chat from "pages/Chat/Chat";
import Kanban from "pages/Kanban"
import AddNewCategory from "pages/Restaurants/AddNewCategory"
import AllCategories from "pages/Restaurants/AllCategories"
import EditNewCategory from "pages/Restaurants/EditNewCategory"
import AddNewRestaurant from "pages/Restaurants/AddNewRestaurant"
import AllRestaurants from "pages/Restaurants/AllRestaurants"
import AddRestaurantLocation from "pages/Restaurants/AddRestaurantLocation"
import AllLocations from "pages/Restaurants/AllLocations"
import EditNewRestaurant from "pages/Restaurants/EditNewRestaurant"
import EditRestaurantLocation from "pages/Restaurants/EditRestaurantLocation"
import AllMenues from "pages/Restaurants/AllMenues"
import AddNewMenu from "pages/Restaurants/AddNewMenu"
import EditNewMenu from "pages/Restaurants/EditNewMenu"
import AllBanks from "pages/Banks/AllBanks"
import AddNewBank from "pages/Banks/AddNewBank"
import EditNewBank from "pages/Banks/EditNewBank"
import AllCards from "pages/Banks/AllCards"
import AddNewCard from "pages/Banks/AddNewCard"
import EditNewCard from "pages/Banks/EditNewCard"
import AllDiscounts from "pages/Restaurants/AllDiscounts"
import AddNewDiscount from "pages/Restaurants/AddNewDiscount"
import EditNewDiscount from "pages/Restaurants/EditNewDiscount"
import AllDishCategories from "pages/dishCategories/AllDishCategories"
import AddNewDishCategory from "pages/dishCategories/AddNewDishCategory"
import EditNewDishCategory from "pages/dishCategories/EditNewDishCategory"
import AddNewDishCuisine from "pages/dishCuisines/AddNewDishCuisine"
import EditNewDishCuisine from "pages/dishCuisines/EditNewDishCuisine"
import AllDishCuisine from "pages/dishCuisines/AllDishCuisine"
import AddNewRecipeCategory from "pages/recipeCategory/AddNewRecipeCategory"
import AllRecipeCategories from "pages/recipeCategory/AllRecipeCategories"
import EditNewRecipeCategory from "pages/recipeCategory/EditNewRecipeCategory"
import AllPendingRecipes from "pages/recipes/AllPendingRecipes "
import AllApprovedRecipes from "pages/recipes/AllApprovedRecipes"
import AllRejectedRecipes from "pages/recipes/AllRejectedRecipes"
import AllPendingRecipeReviews from "pages/reviews/recipe/AllPendingRecipeReviews"
import AllApprovedRecipeReviews from "pages/reviews/recipe/AllApprovedRecipeReviews"
import AllRejectedRecipeReviews from "pages/reviews/recipe/AllRejectedRecipeReviews"
import AllPendingRestaurantReviews from "pages/reviews/restaurant/AllPendingRestaurantReviews"
import AllApprovedRestaurantReviews from "pages/reviews/restaurant/AllApprovedRestaurantReviews"
import AllRejectedRestaurantReviews from "pages/reviews/restaurant/AllRejectedRestaurantReviews"
import Admin from "pages/users/Admin"
import Users from "pages/users/Users"
import AllBanners from "pages/media/AllBanners"
import AddAdmin from "pages/users/AddAdmin"
import EditAdmin from "pages/users/EditAdmin"
import AllAreas from "pages/Locations/AllAreas"
import AddNewArea from "pages/Locations/AddNewArea"
import AddNewBanner from "pages/media/AddNewBanner"
import EditNewArea from "pages/Locations/EditNewArea"
import AllPhases from "pages/Locations/AllPhases"
import AddNewPhase from "pages/Locations/AddNewPhase"
import EditNewPhase from "pages/Locations/EditNewPhase"
import FeaturedRestaurants from "pages/Restaurants/FeaturedRestaurants"
import PendingRestaurants from "pages/Restaurants/PendingRestaurants"
import RejectedRestaurants from "pages/Restaurants/RejectedRestaurants"



const GROUP_ID = localStorage.getItem('group_id')
let userRoutes;

if(GROUP_ID === "3"){
   userRoutes = [
    // { path: "/dashboard", component: <Dashboard /> },
    // { path: "/add-restaurant-category", component: <AddNewCategory /> },
    // { path: "/add-recipe-category", component: <AddNewRecipeCategory /> },
    // { path: "/add-restaurant-dish-category", component: <AddNewDishCategory /> },
    // { path: "/add-restaurant-dish-cuisine", component: <AddNewDishCuisine /> },
    // { path: "/add-bank", component: <AddNewBank /> },
    // { path: "/add-area", component: <AddNewArea /> },
    // { path: "/add-banner", component: <AddNewBanner /> },
    // { path: "/edit-restaurant-category/:id", component: <EditNewCategory /> },
    // { path: "/edit-recipe-category/:id", component: <EditNewRecipeCategory /> },
    // { path: "/edit-restaurant-dish-cuisine/:id", component: <EditNewDishCuisine /> },
    // { path: "/edit-restaurant-dish-category/:id", component: <EditNewDishCategory /> },
    // { path: "/edit-bank/:id", component: <EditNewBank /> },
    // { path: "/all-restaurant-categories", component: <AllCategories /> },
    // { path: "/all-recipe-categories", component: <AllRecipeCategories /> },
    // { path: "/all-restaurant-dish-categories", component: <AllDishCategories /> },
    // { path: "/all-restaurant-dish-cuisines", component: <AllDishCuisine /> },
    // { path: "/all-banks", component: <AllBanks /> },
    // { path: "/all-areas", component: <AllAreas /> },
    // { path: "/all-admins", component: <Admin /> },
    // { path: "/all-banners", component: <AllBanners /> },
    // { path: "/all-users", component: <Users /> },
    // { path: "/edit-area/:id", component: <EditNewArea /> },
    // { path: "/edit-admin/:id", component: <EditAdmin /> },
  
    // { path: "/all-restaurants", component: <AllRestaurants /> },
    // { path: "/featured-restaurants", component: <FeaturedRestaurants /> },
    // { path: "/all-approved-recipes", component: <AllApprovedRecipes /> },
    // { path: "/all-pending-recipes", component: <AllPendingRecipes /> },
    // { path: "/all-rejected-recipes", component: <AllRejectedRecipes /> },
  
    { path: "/all-pending-recipe-reviews", component: <AllPendingRecipeReviews /> },
    { path: "/all-approved-recipe-reviews", component: <AllApprovedRecipeReviews /> },
    { path: "/all-rejected-recipe-reviews", component: <AllRejectedRecipeReviews /> },
    { path: "/all-pending-restaurant-reviews", component: <AllPendingRestaurantReviews /> },
    { path: "/all-approved-restaurant-reviews", component: <AllApprovedRestaurantReviews /> },
    { path: "/all-rejected-restaurant-reviews", component: <AllRejectedRestaurantReviews /> },
  
    // { path: "/add-restaurant", component: <AddNewRestaurant /> },
    // { path: "/add-menu/:id", component: <AddNewMenu /> },
    // { path: "/add-admin", component: <AddAdmin /> },
    // { path: "/edit-admin/:id", component: <EditAdmin /> },
    // { path: "/add-discount/:id", component: <AddNewDiscount /> },
    // { path: "/edit-restaurant/:id/:type", component: <EditNewRestaurant /> },
    // { path: "/add-restaurant-location/:id", component: <AddRestaurantLocation /> },
    // { path: "/add-card/:id", component: <AddNewCard /> },
    // { path: "/add-phase/:id", component: <AddNewPhase /> },
    // { path: "/manage-locations/:id", component: <AllLocations /> },
    // { path: "/manage-cards/:id", component: <AllCards /> },
    // { path: "/manage-phases/:id", component: <AllPhases /> },
    // { path: "/manage-menu/:id", component: <AllMenues /> },
    // { path: "/manage-discounts/:id", component: <AllDiscounts /> },
    // { path: "/edit-location/:id/:restId/:type", component: <EditRestaurantLocation /> },
    // { path: "/edit-menu/:id/:restId", component: <EditNewMenu /> },
    // { path: "/edit-card/:id/:bankId", component: <EditNewCard /> },
    // { path: "/edit-phase/:id/:areaId", component: <EditNewPhase /> },
    // { path: "/edit-discount/:id/:restId", component: <EditNewDiscount /> },
    // //calendar
    { path: "/calendar", component: <Calendar /> },
  
    { path: "/chat", component: <Chat /> },
    { path: "/kanbanboard", component: <Kanban /> },
  
    // // //profile
    { path: "/profile", component: <UserProfile /> },
  
    // //Email
    { path: "/email-inbox", component: <EmailInbox /> },
    { path: "/email-read", component: <EmailRead /> },
    { path: "/email-compose", component: <EmailCompose /> },
  
    // //Charts
    { path: "/apex-charts", component: <ChartsAppex /> },
    { path: "/charts-chartjs", component: <ChartsJs /> },
    { path: "/charts-knob", component: <ChartsKnob /> },
    { path: "/charts-c3", component: <ChartsC3 /> },
    { path: "/sparkline-charts", component: <ChartsSparkLine /> },
  
    // // Icons
    { path: "/icons-materialdesign", component: <IconMaterialdesign /> },
    { path: "/icons-ion", component: <Iconion /> },
    { path: "/icons-fontawesome", component: <IconFontawesome /> },
    { path: "/icons-themify", component: <IconThemify /> },
    { path: "/icons-dripicons", component: <IconDripicons /> },
    { path: "/icons-typicons", component: <IconTypicons /> }, 
    
    // // Tables
    { path: "/tables-basic", component: <BasicTables /> },
    { path: "/tables-datatable", component: <DatatableTables /> },
    { path: "/tables-responsive", component: <ResponsiveTables /> },
    { path: "/tables-editable", component: <EditableTables /> },
  
    // // Maps
    { path: "/maps-google", component: <MapsGoogle /> },
    { path: "/maps-vector", component: <MapsVector /> },
  
    // // Forms
    { path: "/form-elements", component: <FormElements /> },
    { path: "/form-advanced", component: <FormAdvanced /> },
    { path: "/form-editors", component: <FormEditors /> },
    { path: "/form-uploads", component: <FormUpload /> },
    { path: "/form-validation", component: <FormValidations /> },
    { path: "/form-xeditable", component: <FormXeditable /> },
  
    // // Ui
    { path: "/ui-alerts", component: <UiAlert /> },
    { path: "/ui-buttons", component: <UiButtons /> },
    { path: "/ui-badge", component: <UiBadge /> },
    { path: "/ui-cards", component: <UiCards /> },
    { path: "/ui-carousel", component: <UiCarousel /> },
    { path: "/ui-dropdowns", component: <UiDropdown /> },
    { path: "/ui-grid", component: <UiGrid /> },
    { path: "/ui-images", component: <UiImages /> },
    { path: "/ui-lightbox", component: <UiLightbox /> },
    { path: "/ui-modals", component: <UiModal /> },
    { path: "/ui-pagination", component: <UiPagination /> },
    { path: "/ui-popover-tooltip", component: <UiPopoverTooltips /> },
    { path: "/ui-progressbars", component: <UiProgressbar /> },
    { path: "/ui-tabs-accordions", component: <UiTabsAccordions /> },
    { path: "/ui-typography", component: <UiTypography /> },
    { path: "/ui-video", component: <UiVideo /> },
    { path: "/ui-session-timeout", component: <UiSessionTimeout /> },
    { path: "/ui-rangeslider", component: <UiRangeSlider /> },
    { path: "/ui-utilities", component: <UiUtilities /> },
    { path: "/ui-colors", component: <UiColors /> },
    { path: "/ui-offcanvas", component: <UiOffcanvas /> },
  
    // //Extra Pages
    { path: "/pages-timeline", component: <PagesTimeline /> },
    { path: "/pages-invoice", component: <PagesInvoice /> },
    { path: "/pages-directory", component: <PagesDirectory /> },
    { path: "/pages-blank", component: <PagesBlank /> },
  
    // this route should be at the end of all other routes
    {
      path: "/",
      exact: true,
      component: <Navigate to="/all-pending-recipe-reviews" />,
    },
  ]
}else{
  userRoutes = [
    { path: "/dashboard", component: <Dashboard /> },
    { path: "/add-restaurant-category", component: <AddNewCategory /> },
    { path: "/add-recipe-category", component: <AddNewRecipeCategory /> },
    { path: "/add-restaurant-dish-category", component: <AddNewDishCategory /> },
    { path: "/add-restaurant-dish-cuisine", component: <AddNewDishCuisine /> },
    { path: "/add-bank", component: <AddNewBank /> },
    { path: "/add-area", component: <AddNewArea /> },
    { path: "/add-banner", component: <AddNewBanner /> },
    { path: "/edit-restaurant-category/:id", component: <EditNewCategory /> },
    { path: "/edit-recipe-category/:id", component: <EditNewRecipeCategory /> },
    { path: "/edit-restaurant-dish-cuisine/:id", component: <EditNewDishCuisine /> },
    { path: "/edit-restaurant-dish-category/:id", component: <EditNewDishCategory /> },
    { path: "/edit-bank/:id", component: <EditNewBank /> },
    { path: "/all-restaurant-categories", component: <AllCategories /> },
    { path: "/all-recipe-categories", component: <AllRecipeCategories /> },
    { path: "/all-restaurant-dish-categories", component: <AllDishCategories /> },
    { path: "/all-restaurant-dish-cuisines", component: <AllDishCuisine /> },
    { path: "/all-banks", component: <AllBanks /> },
    { path: "/all-areas", component: <AllAreas /> },
    { path: "/all-admins", component: <Admin /> },
    { path: "/all-banners", component: <AllBanners /> },
    { path: "/all-users", component: <Users /> },
    { path: "/edit-area/:id", component: <EditNewArea /> },
    { path: "/edit-admin/:id", component: <EditAdmin /> },
  
    { path: "/all-restaurants", component: <AllRestaurants /> },
    { path: "/pending-restaurants", component: <PendingRestaurants /> },
    { path: "/rejected-restaurants", component: <RejectedRestaurants /> },
    { path: "/featured-restaurants", component: <FeaturedRestaurants /> },
    { path: "/all-approved-recipes", component: <AllApprovedRecipes /> },
    { path: "/all-pending-recipes", component: <AllPendingRecipes /> },
    { path: "/all-rejected-recipes", component: <AllRejectedRecipes /> },
  
    { path: "/all-pending-recipe-reviews", component: <AllPendingRecipeReviews /> },
    { path: "/all-approved-recipe-reviews", component: <AllApprovedRecipeReviews /> },
    { path: "/all-rejected-recipe-reviews", component: <AllRejectedRecipeReviews /> },
    { path: "/all-pending-restaurant-reviews", component: <AllPendingRestaurantReviews /> },
    { path: "/all-approved-restaurant-reviews", component: <AllApprovedRestaurantReviews /> },
    { path: "/all-rejected-restaurant-reviews", component: <AllRejectedRestaurantReviews /> },
  
    { path: "/add-restaurant", component: <AddNewRestaurant /> },
    { path: "/add-menu/:id", component: <AddNewMenu /> },
    { path: "/add-admin", component: <AddAdmin /> },
    { path: "/edit-admin/:id", component: <EditAdmin /> },
    { path: "/add-discount/:id", component: <AddNewDiscount /> },
    { path: "/edit-restaurant/:id/:type", component: <EditNewRestaurant /> },
    { path: "/add-restaurant-location/:id", component: <AddRestaurantLocation /> },
    { path: "/add-card/:id", component: <AddNewCard /> },
    { path: "/add-phase/:id", component: <AddNewPhase /> },
    { path: "/manage-locations/:id", component: <AllLocations /> },
    { path: "/manage-cards/:id", component: <AllCards /> },
    { path: "/manage-phases/:id", component: <AllPhases /> },
    { path: "/manage-menu/:id", component: <AllMenues /> },
    { path: "/manage-discounts/:id", component: <AllDiscounts /> },
    { path: "/edit-location/:id/:restId/:type", component: <EditRestaurantLocation /> },
    { path: "/edit-menu/:id/:restId", component: <EditNewMenu /> },
    { path: "/edit-card/:id/:bankId", component: <EditNewCard /> },
    { path: "/edit-phase/:id/:areaId", component: <EditNewPhase /> },
    { path: "/edit-discount/:id/:restId", component: <EditNewDiscount /> },
    // //calendar
    { path: "/calendar", component: <Calendar /> },
  
    { path: "/chat", component: <Chat /> },
    { path: "/kanbanboard", component: <Kanban /> },
  
    // // //profile
    { path: "/profile", component: <UserProfile /> },
  
    // //Email
    { path: "/email-inbox", component: <EmailInbox /> },
    { path: "/email-read", component: <EmailRead /> },
    { path: "/email-compose", component: <EmailCompose /> },
  
    // //Charts
    { path: "/apex-charts", component: <ChartsAppex /> },
    { path: "/charts-chartjs", component: <ChartsJs /> },
    { path: "/charts-knob", component: <ChartsKnob /> },
    { path: "/charts-c3", component: <ChartsC3 /> },
    { path: "/sparkline-charts", component: <ChartsSparkLine /> },
  
    // // Icons
    { path: "/icons-materialdesign", component: <IconMaterialdesign /> },
    { path: "/icons-ion", component: <Iconion /> },
    { path: "/icons-fontawesome", component: <IconFontawesome /> },
    { path: "/icons-themify", component: <IconThemify /> },
    { path: "/icons-dripicons", component: <IconDripicons /> },
    { path: "/icons-typicons", component: <IconTypicons /> }, 
    
    // // Tables
    { path: "/tables-basic", component: <BasicTables /> },
    { path: "/tables-datatable", component: <DatatableTables /> },
    { path: "/tables-responsive", component: <ResponsiveTables /> },
    { path: "/tables-editable", component: <EditableTables /> },
  
    // // Maps
    { path: "/maps-google", component: <MapsGoogle /> },
    { path: "/maps-vector", component: <MapsVector /> },
  
    // // Forms
    { path: "/form-elements", component: <FormElements /> },
    { path: "/form-advanced", component: <FormAdvanced /> },
    { path: "/form-editors", component: <FormEditors /> },
    { path: "/form-uploads", component: <FormUpload /> },
    { path: "/form-validation", component: <FormValidations /> },
    { path: "/form-xeditable", component: <FormXeditable /> },
  
    // // Ui
    { path: "/ui-alerts", component: <UiAlert /> },
    { path: "/ui-buttons", component: <UiButtons /> },
    { path: "/ui-badge", component: <UiBadge /> },
    { path: "/ui-cards", component: <UiCards /> },
    { path: "/ui-carousel", component: <UiCarousel /> },
    { path: "/ui-dropdowns", component: <UiDropdown /> },
    { path: "/ui-grid", component: <UiGrid /> },
    { path: "/ui-images", component: <UiImages /> },
    { path: "/ui-lightbox", component: <UiLightbox /> },
    { path: "/ui-modals", component: <UiModal /> },
    { path: "/ui-pagination", component: <UiPagination /> },
    { path: "/ui-popover-tooltip", component: <UiPopoverTooltips /> },
    { path: "/ui-progressbars", component: <UiProgressbar /> },
    { path: "/ui-tabs-accordions", component: <UiTabsAccordions /> },
    { path: "/ui-typography", component: <UiTypography /> },
    { path: "/ui-video", component: <UiVideo /> },
    { path: "/ui-session-timeout", component: <UiSessionTimeout /> },
    { path: "/ui-rangeslider", component: <UiRangeSlider /> },
    { path: "/ui-utilities", component: <UiUtilities /> },
    { path: "/ui-colors", component: <UiColors /> },
    { path: "/ui-offcanvas", component: <UiOffcanvas /> },
  
    // //Extra Pages
    { path: "/pages-timeline", component: <PagesTimeline /> },
    { path: "/pages-invoice", component: <PagesInvoice /> },
    { path: "/pages-directory", component: <PagesDirectory /> },
    { path: "/pages-blank", component: <PagesBlank /> },
  
    // this route should be at the end of all other routes
    {
      path: "/",
      exact: true,
      component: <Navigate to="/dashboard" />,
    },
  ]
}

const authRoutes = [
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPwd /> },
  { path: "/register", component: <Register /> },

  { path: "/pages-404", component: <Pages404 /> },
  { path: "/pages-500", component: <Pages500 /> },

  // Authentication Inner
  { path: "/pages-login", component: <Login1 /> },
  { path: "/pages-register", component: <Register1 /> },
  { path: "/page-recoverpw", component: <Recoverpw /> },
  { path: "/auth-lock-screen", component: <LockScreen /> },
]

export { userRoutes, authRoutes }