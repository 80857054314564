import PropTypes from "prop-types"
import React, { createContext, useEffect, useState } from "react"

import { Route, Routes } from "react-router-dom"
import { connect } from "react-redux"

// Import Routes all
import { userRoutes, authRoutes } from "./routes/allRoutes"

// Import all middleware
import Authmiddleware from "./routes/middleware/Authmiddleware"

// layouts Format
import VerticalLayout from "./components/VerticalLayout/"
import HorizontalLayout from "./components/HorizontalLayout/"
import NonAuthLayout from "./components/NonAuthLayout"

// Import scss
import "./assets/scss/theme.scss"

// Import Firebase Configuration file
// import { initFirebaseBackend } from "./helpers/firebase_helper"

import fakeBackend from "./helpers/AuthType/fakeBackend"
import Login from "pages/Authentication/Login"

const RestaurantPageContext = createContext()

const ProfileContext = createContext()

// Activating fake backend
fakeBackend()

// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_APIKEY,
//   authDomain: process.env.REACT_APP_AUTHDOMAIN,
//   databaseURL: process.env.REACT_APP_DATABASEURL,
//   projectId: process.env.REACT_APP_PROJECTID,
//   storageBucket: process.env.REACT_APP_STORAGEBUCKET,
//   messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
//   appId: process.env.REACT_APP_APPID,
//   measurementId: process.env.REACT_APP_MEASUREMENTID,
// }

// init firebase backend
// initFirebaseBackend(firebaseConfig)

const App = props => {

  const [page, setPage] = useState(1)
  const [profileUpdate, setProfileUpdate] = useState(0);
  // {alert('hiii')}
  //   useEffect(() => {
  //     alert('hii')
  //     document.getElementsByTagName("html")[0].setAttribute("dir", "rtl");
  //   }, [])

  function getLayout() {
    let layoutCls = HorizontalLayout
    switch (props.layout.layoutType) {
      case "vertical":
        layoutCls = VerticalLayout
        break
      default:
        layoutCls = HorizontalLayout
        break
    }
    return layoutCls
  }



  const Layout = getLayout()
  return (
    <React.Fragment>
      <Routes>
        <Route path="/login" element={<Login />} />
        {userRoutes.map((route, idx) => (
          <Route
            key={idx}
            path={route.path}
            element={
              <Authmiddleware>
                <RestaurantPageContext.Provider value={{page, setPage}}>
                <ProfileContext.Provider value={{profileUpdate, setProfileUpdate}}>
                <VerticalLayout>{route.component}</VerticalLayout>

                </ProfileContext.Provider>
               
                {/* <HorizontalLayout>{route.component}</HorizontalLayout> */}
                </RestaurantPageContext.Provider>
              </Authmiddleware>
            }
          />
        ))}
      </Routes>
    </React.Fragment>
  )
}

App.propTypes = {
  layout: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  }
}

export default connect(mapStateToProps, null)(App)
export {ProfileContext, RestaurantPageContext}
